import { NPSLowRatingReason } from './models';

export const CAPTERRA_FEEDBACK_URL = 'https://reviews.capterra.com/new/240972/4a195b33-bda7-4267-9008-1e904e991c48?lang=en';

export const RATING_STAR_IMAGES = {
  1: 'https://res.cloudinary.com/hevo/image/upload/v1715270307/dashboard/rating-1_qz6xyg.svg',
  2: 'https://res.cloudinary.com/hevo/image/upload/v1715270306/dashboard/rating-2_x3f4r0.svg',
  3: 'https://res.cloudinary.com/hevo/image/upload/v1715270308/dashboard/rating-3_ddhbch.svg',
  4: 'https://res.cloudinary.com/hevo/image/upload/v1715270307/dashboard/rating-4_qgiyro.svg',
  5: 'https://res.cloudinary.com/hevo/image/upload/v1715270307/dashboard/rating-5_pdjwsm.svg',
  6: 'https://res.cloudinary.com/hevo/image/upload/v1715270306/dashboard/rating-6_jswxmz.svg',
  7: 'https://res.cloudinary.com/hevo/image/upload/v1716190787/dashboard/rating-7_uytifp.svg',
  9: 'https://res.cloudinary.com/hevo/image/upload/v1661186305/dashboard/rating-ty-09_rsy0sb.svg',
  10: 'https://res.cloudinary.com/hevo/image/upload/v1661186305/dashboard/rating-ty-10_nabcel.svg'
};

export const NPS_LOW_RATING_REASONS: NPSLowRatingReason[] = [
  {
    name: 'Pricing',
    value: 'PRICING'
  },
  {
    name: 'Product Issue',
    value: 'PRODUCT_ISSUE'
  },
  {
    name: 'Feature Gaps',
    value: 'FEATURE_GAPS'
  },
  {
    name: 'Support',
    value: 'SUPPORT'
  },
  {
    name: 'Others',
    value: 'OTHERS'
  }
];

export const NPS_SCHEDULE_CALL_LINK = 'https://meetings.hubspot.com/hevodata/nps';

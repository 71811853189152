import { AdrollConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/AdRoll/adroll-config-helper';
import { AmazonAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/AmazonAds/configHelper';
import { AmplitudeAnalyticsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/AmplitudeAnalytics/configHelper';
import { AppleSearchAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/AppleSearchAds/configHelper';
import { AppsflyerConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Appsflyer/appsflyer-config-helper';
import { AsanaConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Asana/configHelper';
import { AwsDocumentdbConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/AwsDocumentDb/configHelper';
import { AzureBlobConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/AzureBlob/azure-blob-config-helper';
import { BigQuerySourceConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Bigquery/big-query-config-helper';
import { BraintreePaymentsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/BraintreePayments/braintree-payments-config-helper';
import { BrazeConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Braze/braze-config-helper';
import { ChargebeeConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Chargebee/chargebee-config-helper';
import { ClevertapConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Clevertap/clevertap-config-helper';
import { CriteoConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Criteo/criteo-config-helper';
import { DynamodbConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/DynamoDb/configHelper';
import { ElasticSearchConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/ElasticSearch/configHelper';
import { ElasticSearchVariations } from '../../../react/containers/pipeline/create/ConfigSourceType/ElasticSearch/models';
import { ELASTICSEARCH_VARIATIONS } from '../../../react/containers/pipeline/create/ConfigSourceType/ElasticSearch/variantions';
import { FbaConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/FacebookAds/configHelper';
import { FbInsightsConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/FacebookPages/configHelper';
import { FirebaseAnalyticsConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/FireBaseAnalytics/configHelper';
import { FrontConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Front/configHelper';
import { FtpConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/FTP/configHelper';
import { GcsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Gcs/configHelper';
import { GoogleAdsReactConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleAds/configHelper';
import { GoogleAnalytics360ConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleAnalytics360/configHelper';
import { GoogleAnalyticsConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleAnalytics/configHelper';
import { GoogleAnalytics4ConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleAnalytics4/configHelper';
import { GoogleCampaignManagerConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleCampaignManager/configHelper';

import { DriveConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleDrive/configHelper';
import { GooglePlayConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/GooglePlay/configHelper';
import { GoogleSearchConsoleConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleSearchConsole/configHelper';
import { GoogleSheetsReactConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleSheets/configHelper';
import { InstagramBusinessConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/InstagramBusiness/instagram-business-config-helper';
import { JiraConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Jira/jira-config-helper';
import { KafkaConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Kafka/configHelper';
import { KafkaVariations } from '../../../react/containers/pipeline/create/ConfigSourceType/Kafka/model';
import { KAFKA_VARIATIONS } from '../../../react/containers/pipeline/create/ConfigSourceType/Kafka/variations';
import { LinkedinAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/LinkedInAds/config-helper';
import { MarketoConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Marketo/marketo-config-helper';
import { MixpanelConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Mixpanel/configHelper';
import { MockLoadConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/MockLoad/config-helper';
import { MongodbConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/MongoDb/configHelper';
import { MongodbVariations } from '../../../react/containers/pipeline/create/ConfigSourceType/MongoDb/models';
import { MONGODB_VARIATIONS } from '../../../react/containers/pipeline/create/ConfigSourceType/MongoDb/variations';
import { OutbrainConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Outbrain/config-helper';
import { PardotConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Pardot/configHelper';
import { PendoConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Pendo/pendo-config-helper';
import { PinterestAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/PinterestAds/pinterest-ads-config-helper';
import { RecurlyConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Recurly/recurly-config-helper';
import { S3ConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/S3/configHelper';
import { SalesforceMarketingCloudConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/SalesforceMarketingCloud/salesforce-marketing-cloud-config-helper';
import { SendgridConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/SendGrid/sendgrid-config-helper';
import { ShopifyConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Shopify/config-helper';
import { SnapchatAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Snapchat/config-helper';
import { SqlConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/SQL/configHelper';
import { StripeConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Stripe/stripe-config-helper';
import { TaboolaConfigHelperReact } from '../../../react/containers/pipeline/create/ConfigSourceType/taboola/configHelper';
import { TiktokAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/TiktokAds/tiktok-ads-config-helper';
import { TwilioConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Twilio/twilio-config-helper';
import { TwitterAdsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/TwitterAds/configHelper';
import { TypeformConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Typeform/typeform-config-helper';
import { WebhookConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Webhook/webhook-config-helper';
import { XeroConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/Xero/xero-config-helper';
import { YoutubeAnalyticsConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/YoutubeAnalytics/configHelper';
import { FREQUENCY_TYPE_HOURS } from '../../dialog/execution-policy-dialog/constants';
import { FrequencyType } from '../../dialog/execution-policy-dialog/models';
import { ViewLibrary } from '../../react-bridge/models/view-library';
import { EntityVariationData } from '../nodes-settings/models';
import { MSSQL_VARIATIONS, MYSQL_VARIATIONS, ORACLE_VARIATIONS, POSTGRE_VARIATIONS } from '../nodes-settings/sql/constants';
import { SqlVariations } from '../nodes-settings/sql/models';
import { AuthAccountEnvironment } from '../select-auth-account-environment/interface';
import { SourceTypeConfigHelperConstructor } from '../source-settings-form/config-helper';
import { JobMode, JobModeEnum } from './job-mode';
import { MicrosoftAdsReactConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/MicrosoftAds/configHelper';
import { RestApiReactConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/RestApi/rest-api-config-helper';

declare const __HIDDEN_SOURCES__: { [key: string]: boolean };

export interface RawSourceType {
  source_type: string;
  source_type_display: string;
  base_source_type: string;
  source_type_logo_url: string;

  [key: string]: any;
}

export interface SourceType {
  name: string;
  jobModes?: JobMode[];
  isJDBCType?: boolean;
  isWebhookType?: boolean;
  isFolderSelectorType?: boolean;
  provideSchemaName?: boolean;
  canUseExisting?: boolean;
  requireAuth?: boolean;
  testEnabled?: boolean;
  authProvider?: string;
  authAccountLabel?: string;
  authProviderClass?: string;
  authProviderIcon?: string;
  isAuthTypeGoogle?: boolean;
  canValidateAdsAccount?: boolean;
  linkedAccount?: string;
  docLink?: string;
  primaryColor?: string;
  darkModePrimaryColor?: string;
  selectObjects?: boolean | SelectObjectsFn;
  canShowAccessibleObjects?: boolean;
  configureObjects?: boolean | ConfigureObjectsFn;
  beta?: boolean;
  privateBeta?: boolean;
  canShowSideBySideDocs?: boolean;
  authAccountsEnvironmentsMetaData?: { [key: string]: AuthAccountEnvironmentMetaData };
  variations?: EntityVariationData[];
  variation?: string;
  sslAllowed?: boolean;
  sslCARequired?: boolean;
  showHistoricalLoadOptions?: boolean;
  isSchemaNameRequired?: boolean;
  parent?: string;
  requireServiceAuth?: boolean;
  canShowViewSchemaOption?: boolean;
  canSkipNewTables?: boolean;
  canShowSourceVersionStep?: boolean;
  configHelper?: SourceTypeConfigHelperConstructor;
  formRenderingLibrary?: ViewLibrary;
  genericReactComponent?: boolean;
  isHidden?: boolean;
  canDiagnoseConnectionErrors?: boolean;
  hasFullLoadFrequency?: boolean;
  showSBSTabs?: boolean;
  canDisableFormSubmitCTAsForInvalidForm?: boolean;
  canShowHelpNotes?: boolean;
  canShowFixedActionButtons?: boolean;
  earlyAccess?: boolean;
  isReauthDisabled?: boolean;
  isUserAccountDisabled?: boolean;
  isServiceAccountMigrationModeEnabled?: boolean;
  pipelineSchedulerBasedDefaultFrequency?: {
    frequencyValue: number,
    frequencyType: FrequencyType
  };
}

export type SelectObjectsFn = (...args) => boolean;
export type ConfigureObjectsFn = (...args) => boolean;

export interface AuthAccountEnvironmentMetaData {
  label: string;
  description: string;
}

export const SOURCE_TYPES: { [key: string]: SourceType } = {
  'REDSHIFT': {
    name: 'REDSHIFT',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/data-warehouses/amazon-redshift/',
    primaryColor: 'rgb(59, 149, 212)',
    darkModePrimaryColor: '#66A2DF',
    selectObjects: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'POSTGRES': {
    name: 'POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.LOGIC_REPL,
      JobModeEnum.XMIN,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/generic-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variations: POSTGRE_VARIATIONS,
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'AURORA': {
    name: 'AURORA',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG_AURORA,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/amazon-aurora-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.AURORA,
    parent: 'MYSQL',
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canShowHelpNotes: true,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'MS_SQL': {
    name: 'MS_SQL',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.CHANGE_TRACKING,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mssql/generic-mssql/',
    selectObjects: true,
    variations: MSSQL_VARIATIONS,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    primaryColor: 'rgb(255, 140, 155)',
    isSchemaNameRequired: true,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AZURE_SQL': {
    name: 'AZURE_SQL',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.CHANGE_TRACKING,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mssql/azure-mssql/',
    selectObjects: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    primaryColor: 'rgb(255, 140, 155)',
    variation: SqlVariations.AZURE,
    parent: 'MS_SQL',
    canSkipNewTables: true,
    isSchemaNameRequired: true,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'ORACLE': {
    name: 'ORACLE',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.REDO_LOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/oracle/generic-oracle/',
    primaryColor: 'rgb(255, 0, 0)',
    darkModePrimaryColor: '#FE0200',
    selectObjects: true,
    variations: ORACLE_VARIATIONS,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'MYSQL': {
    name: 'MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/generic-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variations: MYSQL_VARIATIONS,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'MARIA_DB': {
    name: 'MARIA_DB',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/mariadb-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    // variations: MARIADB_VARIATIONS,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'MONGODB': {
    name: 'MONGODB',
    jobModes: [
      // JobModeEnum.COLLECTION,
      JobModeEnum.OPLOG,
      JobModeEnum.CHANGE_STREAMS
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mongodb/generic-mongodb/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    primaryColor: 'rgb(0, 171, 48)',
    selectObjects: true,
    configureObjects: shouldAllowObjectsConfigurationForMongodb,
    darkModePrimaryColor: '#00AB30',
    canSkipNewTables: true,
    variations: MONGODB_VARIATIONS,
    configHelper: MongodbConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    canDiagnoseConnectionErrors: true,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'ATLAS_MONGODB': {
    name: 'ATLAS_MONGODB',
    jobModes: [
      // JobModeEnum.COLLECTION,
      JobModeEnum.OPLOG,
      JobModeEnum.CHANGE_STREAMS
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mongodb/mongodb-atlas/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    primaryColor: 'rgb(0, 171, 48)',
    selectObjects: true,
    configureObjects: shouldAllowObjectsConfigurationForMongodb,
    darkModePrimaryColor: '#00AB30',
    variation: MongodbVariations.ATLAS,
    canSkipNewTables: true,
    parent: 'MONGODB',
    configHelper: MongodbConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    canDiagnoseConnectionErrors: true,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'MAILCHIMP_WEBHOOK': {
    name: 'MAILCHIMP_WEBHOOK',
    isWebhookType: true,
    docLink: '/sources/marketing/mailchimp/',
    primaryColor: 'rgb(104, 76, 70)',
    darkModePrimaryColor: '#F3BE77',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GITHUB': {
    name: 'GITHUB',
    isWebhookType: true,
    docLink: '/sources/collaboration/github/',
    primaryColor: 'rgb(0, 114, 198)',
    darkModePrimaryColor: '#B6B6B6',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'INTERCOM_WEBHOOK': {
    name: 'INTERCOM_WEBHOOK',
    isWebhookType: true,
    docLink: '/sources/crm/intercom/intercom-webhook/',
    primaryColor: 'rgb(30, 141, 237)',
    darkModePrimaryColor: '#53AAF5',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'INTERCOM': {
    name: 'INTERCOM',
    requireAuth: true,
    canUseExisting: true,
    testEnabled: true,
    authProvider: 'INTERCOM',
    docLink: '/sources/crm/intercom/intercom-app/',
    primaryColor: 'rgb(30, 141, 237)',
    selectObjects: true,
    darkModePrimaryColor: '#53AAF5',
    canShowViewSchemaOption: true,
    configHelper: null,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SHOPIFY_WEBHOOK': {
    name: 'SHOPIFY_WEBHOOK',
    isWebhookType: true,
    docLink: '/sources/e-commerce/shopify/shopify-webhook/',
    primaryColor: 'rgb(149, 190, 70)',
    darkModePrimaryColor: '#ACD65E',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SEGMENT': {
    name: 'SEGMENT',
    isWebhookType: true,
    docLink: '/sources/marketing/segment/',
    primaryColor: 'rgb(100, 214, 101)',
    darkModePrimaryColor: '#63DEA2',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SENDGRID_WEBHOOK': {
    name: 'SENDGRID_WEBHOOK',
    isWebhookType: true,
    docLink: '/sources/marketing/sendgrid-webhook/',
    primaryColor: 'rgb(0, 182, 232)',
    darkModePrimaryColor: '#27B4E1',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ANDROID_SDK': {
    name: 'ANDROID_SDK',
    isWebhookType: true,
    docLink: '/sources/streaming/android-sdk/',
    primaryColor: 'rgb(154, 199, 0)',
    darkModePrimaryColor: '#BEE439',
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'WEBHOOK': {
    name: 'WEBHOOK',
    isWebhookType: true,
    canUseExisting: true,
    docLink: '/sources/streaming/webhook/',
    primaryColor: 'rgb(75, 75, 75)',
    darkModePrimaryColor: '#F45E89',
    canDisableFormSubmitCTAsForInvalidForm: true,
    configHelper: WebhookConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FRESHDESK': {
    name: 'FRESHDESK',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/crm/freshdesk/',
    primaryColor: 'rgb(0, 133, 93)',
    darkModePrimaryColor: '#4AEA96',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    selectObjects: true,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'KAFKA': {
    name: 'KAFKA',
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFFFFF',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variations: KAFKA_VARIATIONS,
    sslAllowed: true,
    sslCARequired: true,
    docLink: '/sources/streaming/kafka/apache-kafka/',
    configHelper: KafkaConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'MIXPANEL': {
    name: 'MIXPANEL',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/analytics/mixpanel/',
    primaryColor: 'rgb(79, 126, 173)',
    darkModePrimaryColor: '#BBC4DB',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    selectObjects: true,
    configHelper: MixpanelConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    showHistoricalLoadOptions: true
  },
  'GOOGLE_SHEETS': {
    name: 'GOOGLE_SHEETS',
    authProvider: 'GOOGLE_SHEETS',
    isAuthTypeGoogle: true,
    testEnabled: true,
    docLink: '/sources/dbfs/file-storage/google-sheets/',
    primaryColor: 'rgb(34, 165, 101)',
    darkModePrimaryColor: '#23A566',
    requireServiceAuth: true,
    configHelper: GoogleSheetsReactConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    isReauthDisabled: true,
    isServiceAccountMigrationModeEnabled: true,
    isUserAccountDisabled: true
  },
  'DRIVE': {
    name: 'DRIVE',
    docLink: '/sources/dbfs/file-storage/google-drive/',
    primaryColor: 'rgb(5, 170, 96)',
    darkModePrimaryColor: '#3CDF94',
    authProvider: 'DRIVE',
    isAuthTypeGoogle: true,
    requireServiceAuth: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: DriveConfigHelperReact,
    isReauthDisabled: true,
    isServiceAccountMigrationModeEnabled: true,
    isUserAccountDisabled: true
  },
  'SALESFORCE': {
    name: 'SALESFORCE',
    requireAuth: true,
    docLink: '/sources/crm/salesforce/',
    primaryColor: 'rgb(0, 163, 230)',
    darkModePrimaryColor: '#00A1E0',
    jobModes: [ JobModeEnum.SALESFORCE ],
    authProvider: 'SALESFORCE',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    authAccountsEnvironmentsMetaData: {
      [AuthAccountEnvironment.PRODUCTION]: {
        label: 'Production',
        description: 'The environment where live business data is stored'
      },
      [AuthAccountEnvironment.TESTING]: {
        label: 'Sandbox',
        description: 'An isolated environment used for development and testing'
      }
    },
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    canSkipNewTables: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'SALESFORCE_V2': {
    name: 'SALESFORCE_V2',
    requireAuth: true,
    docLink: '/sources/crm/salesforce/',
    primaryColor: 'rgb(0, 163, 230)',
    darkModePrimaryColor: '#00A1E0',
    jobModes: [ JobModeEnum.SALESFORCE ],
    authProvider: 'SALESFORCE',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    authAccountsEnvironmentsMetaData: {
      [AuthAccountEnvironment.PRODUCTION]: {
        label: 'Production',
        description: 'The environment where live business data is stored'
      },
      [AuthAccountEnvironment.TESTING]: {
        label: 'Sandbox',
        description: 'An isolated environment used for development and testing'
      }
    },
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    canSkipNewTables: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'CLEVERTAP': {
    name: 'CLEVERTAP',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/mkt-analytics/clevertap-source/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    primaryColor: 'rgb(72, 26, 188)',
    darkModePrimaryColor: '#8C6AFF',
    configHelper: ClevertapConfigHelper,
    selectObjects: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'S3': {
    name: 'S3',
    canUseExisting: true,
    testEnabled: false,
    isFolderSelectorType: true,
    docLink: '/sources/dbfs/file-storage/amazon-s3/',
    primaryColor: 'rgb(224, 82, 67)',
    darkModePrimaryColor: '#E05243',
    canShowSideBySideDocs: true,
    showSBSTabs: false,
    configHelper: S3ConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GCS': {
    name: 'GCS',
    testEnabled: true,
    authProvider: 'GCS_CONNECTOR',
    isAuthTypeGoogle: true,
    primaryColor: 'rgb(67, 134, 249)',
    darkModePrimaryColor: '#76A8FF',
    docLink: '/sources/dbfs/file-storage/google-cloud-storage-(gcs)/',
    canShowSideBySideDocs: true,
    configHelper: GcsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    requireServiceAuth: true,
    showSBSTabs: true
  },
  'GOOGLE_SEARCH_CONSOLE': {
    name: 'GOOGLE_SEARCH_CONSOLE',
    testEnabled: true,
    requireAuth: true,
    authProvider: 'GOOGLE_SEARCH_CONSOLE',
    isAuthTypeGoogle: true,
    primaryColor: 'rgb(96, 127, 245)',
    docLink: '/sources/marketing/google-search-console/',
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: GoogleSearchConsoleConfigHelperReact
  },
  'GOOGLE_PLAY_CONSOLE': {
    name: 'GOOGLE_PLAY_CONSOLE',
    testEnabled: true,
    requireAuth: true,
    authProvider: 'GOOGLE_PLAY_CONSOLE',
    isAuthTypeGoogle: true,
    primaryColor: '#8AA7B9',
    darkModePrimaryColor: '#8AA7B9',
    docLink: '/sources/marketing/google-play/',
    canShowViewSchemaOption: true,
    configHelper: GooglePlayConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'APPSFLYER': {
    name: 'APPSFLYER',
    jobModes: [ JobModeEnum.APPSFLYER ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/marketing/appsflyer/',
    primaryColor: 'rgb(133, 207, 2)',
    selectObjects: true,
    configureObjects: true,
    darkModePrimaryColor: '#99DB52',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: AppsflyerConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GOOGLE_ANALYTICS': {
    name: 'GOOGLE_ANALYTICS',
    requireAuth: true,
    testEnabled: true,
    docLink: '/sources/analytics/google-analytics/',
    primaryColor: 'rgb(243, 109, 0)',
    darkModePrimaryColor: '#E37400',
    authProvider: 'GOOGLE_ANALYTICS',
    canShowSideBySideDocs: true,
    canValidateAdsAccount: true,
    showSBSTabs: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: GoogleAnalyticsConfigHelperReact
  },
  'GOOGLE_ANALYTICS_V2': {
    name: 'GOOGLE_ANALYTICS_V2',
    requireAuth: true,
    testEnabled: true,
    docLink: '/sources/analytics/google-analytics/',
    primaryColor: 'rgb(243, 109, 0)',
    darkModePrimaryColor: '#E37400',
    authProvider: 'GOOGLE_ANALYTICS',
    canShowSideBySideDocs: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: GoogleAnalyticsConfigHelperReact
  },
  'MICROSOFT_ADS': {
    name: 'MICROSOFT_ADS',
    requireAuth: true,
    selectObjects: true,
    docLink: '/sources/marketing/microsoft-ads/',
    primaryColor: '#3B877B',
    darkModePrimaryColor: '#3B877B',
    authProvider: 'MICROSOFT',
    canShowViewSchemaOption: true,
    configHelper: MicrosoftAdsReactConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    pipelineSchedulerBasedDefaultFrequency: {
      frequencyValue: 12,
      frequencyType: FREQUENCY_TYPE_HOURS
    }
  },
  'FACEBOOK_ADS': {
    name: 'FACEBOOK_ADS',
    requireAuth: true,
    testEnabled: false,
    canShowFixedActionButtons: true,
    docLink: '/sources/marketing/facebook-ads/',
    primaryColor: 'rgb(68, 89, 151)',
    darkModePrimaryColor: '#6393FF',
    authProvider: 'FACEBOOK_ADS',
    authProviderClass: 'fb',
    authProviderIcon: 'https://res.cloudinary.com/hevo/image/upload/v1661186325/dashboard/fb-logo-white_aymaq1.svg',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    showHistoricalLoadOptions: true,
    isHidden: typeof __HIDDEN_SOURCES__ !== 'undefined' && __HIDDEN_SOURCES__.FACEBOOK_ADS,
    selectObjects: (source: any) => {
      return !source.customReport;
    },
    canShowViewSchemaOption: true,
    canValidateAdsAccount: true,
    configHelper: FbaConfigHelper ,
    formRenderingLibrary: ViewLibrary.REACT,
    pipelineSchedulerBasedDefaultFrequency: {
      frequencyValue: 12,
      frequencyType: FREQUENCY_TYPE_HOURS
    }
  },
  'ELASTICSEARCH': {
    name: 'ELASTICSEARCH',
    jobModes: [ JobModeEnum.ELASTICSEARCH ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/databases/elasticsearch/',
    primaryColor: '#00c1b1',
    darkModePrimaryColor: '#00c1b1',
    selectObjects: true,
    configureObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    variations: ELASTICSEARCH_VARIATIONS,
    configHelper: ElasticSearchConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FTP': {
    name: 'FTP',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/file-storage/ftp---sftp/',
    primaryColor: 'rgb(2, 175, 255)',
    darkModePrimaryColor: '#73D2FF',
    canShowSideBySideDocs: true,
    configHelper: FtpConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    showSBSTabs: true
  },
  'RESTAPI': {
    name: 'RESTAPI',
    canUseExisting: true,
    docLink: '/sources/streaming/rest-api/',
    primaryColor: 'rgb(18, 161, 223)',
    darkModePrimaryColor: '#4285F4',
    testEnabled: true,
    authProvider: 'REST_API',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: RestApiReactConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ZENDESK': {
    name: 'ZENDESK',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/crm/zendesk/',
    primaryColor: 'rgb(0, 55, 62)',
    darkModePrimaryColor: '#3F8C9E',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true,
    hasFullLoadFrequency: true
  },
  'ZENDESK_SELL': {
    name: 'ZENDESK_SELL',
    canUseExisting: false,
    testEnabled: true,
    primaryColor: '#D4AE5E',
    darkModePrimaryColor: '#D4AE5E',
    canShowSideBySideDocs: false,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'DIXA': {
    name: 'DIXA',
    testEnabled: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/sns-analytics/dixa',
    primaryColor: '#5A43E0',
    darkModePrimaryColor: '#5A43E0',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'RECHARGE': {
    name: 'RECHARGE',
    testEnabled: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/mkt-analytics/recharge',
    primaryColor: '#3901F1',
    darkModePrimaryColor: '#3901F1',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PAGER_DUTY': {
    name: 'PAGER_DUTY',
    testEnabled: true,
    primaryColor: '#06AC38',
    darkModePrimaryColor: '#06AC38',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TOGGL_TRACK': {
    name: 'TOGGL_TRACK',
    testEnabled: true,
    primaryColor: '#E57CD8',
    darkModePrimaryColor: '#E57CD8',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ONFLEET': {
    name: 'ONFLEET',
    testEnabled: true,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFFFFF',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'MISSIVE': {
    name: 'MISSIVE',
    testEnabled: true,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFFFFF',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'LEMLIST': {
    name: 'LEMLIST',
    testEnabled: true,
    canShowSideBySideDocs: true,
    docLink: '/sources/mkt-analytics/lemlist',
    primaryColor: '#4049FF',
    darkModePrimaryColor: '#9799FE',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'OPSGENIE': {
    name: 'OPSGENIE',
    testEnabled: true,
    primaryColor: '#2684FF',
    darkModePrimaryColor: '#2684FF',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'WORKABLE': {
    name: 'WORKABLE',
    testEnabled: true,
    primaryColor: '#00756A',
    darkModePrimaryColor: '#FFFFFF',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/fna-analytics/workable/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'OUTREACH': {
    name: 'OUTREACH',
    testEnabled: false,
    requireAuth: true,
    authProvider: 'OUTREACH',
    primaryColor: '#4943D1',
    darkModePrimaryColor: '#4943D1',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/sns-analytics/outreach/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PINGDOM': {
    name: 'PINGDOM',
    testEnabled: true,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFF000',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/engg-analytics/pingdom/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ZOHO_CRM': {
    name: 'ZOHO_CRM',
    testEnabled: true,
    requireAuth: true,
    authProvider: 'ZOHO_CRM',
    primaryColor: '#D62033',
    darkModePrimaryColor: '#D62033',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'QUICKBASE': {
    name: 'QUICKBASE',
    testEnabled: true,
    primaryColor: '#2C9382',
    darkModePrimaryColor: '#2C9382',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TRELLO': {
    name: 'TRELLO',
    testEnabled: true,
    primaryColor: '#075BD5',
    darkModePrimaryColor: '#075BD5',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'CLOSE': {
    name: 'CLOSE',
    testEnabled: true,
    primaryColor: '#47B178',
    darkModePrimaryColor: '#47B178',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'HARVEST': {
    name: 'HARVEST',
    testEnabled: true,
    canShowSideBySideDocs: true,
    docLink: '/sources/fna-analytics/harvest/',
    showSBSTabs: true,
    primaryColor: '#FA5D00',
    darkModePrimaryColor: '#FA5D00',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'DYNAMODB': {
    name: 'DYNAMODB',
    jobModes: [ JobModeEnum.DYNAMODB ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/databases/amazon-dynamodb/',
    primaryColor: 'rgb(59, 149, 212)',
    darkModePrimaryColor: '#318BE7',
    selectObjects: true,
    configureObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    configHelper: DynamodbConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'HUBSPOT': {
    name: 'HUBSPOT',
    docLink: '/sources/marketing/hubspot/',
    requireAuth: true,
    authProvider: 'HUBSPOT',
    primaryColor: 'rgb(255, 122, 89)',
    selectObjects: true,
    darkModePrimaryColor: '#FF7A59',
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'MAILCHIMP': {
    name: 'MAILCHIMP',
    requireAuth: true,
    authProvider: 'MAILCHIMP',
    primaryColor: 'rgb(104, 76, 70)',
    darkModePrimaryColor: '#F3BE77',
    docLink: '/sources/marketing/mailchimp/',
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    selectObjects: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'SENDGRID': {
    name: 'SENDGRID',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/marketing/sendgrid/',
    primaryColor: 'rgb(0, 182, 232)',
    selectObjects: true,
    darkModePrimaryColor: '#27B4E1',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: SendgridConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TWITTER_ADS': {
    name: 'TWITTER_ADS',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    primaryColor: 'rgb(29, 161, 242)',
    darkModePrimaryColor: '#1DA1F2',
    selectObjects: true,
    configureObjects: false,
    docLink: '/sources/marketing/twitter-ads/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: TwitterAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    pipelineSchedulerBasedDefaultFrequency: {
      frequencyValue: 12,
      frequencyType: FREQUENCY_TYPE_HOURS
    }
  },
  'MARKETO': {
    name: 'MARKETO',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#5B4C9E',
    darkModePrimaryColor: '#B6B6B6',
    docLink: '/sources/marketing/marketo/',
    configHelper: MarketoConfigHelper,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    selectObjects: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SHOPIFY': {
    name: 'SHOPIFY',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    primaryColor: 'rgb(149, 190, 70)',
    darkModePrimaryColor: '#ACD65E',
    selectObjects: true,
    canShowAccessibleObjects: true,
    docLink: '/sources/e-commerce/shopify/shopify-app/',
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: ShopifyConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'STRIPE': {
    name: 'STRIPE',
    jobModes: [],
    testEnabled: true,
    primaryColor: '#6772E5',
    darkModePrimaryColor: '#6772E5',
    selectObjects: true,
    canShowAccessibleObjects: true,
    docLink: '/sources/finance-&-accounting/stripe/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    showHistoricalLoadOptions: true,
    configHelper: StripeConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'JIRA_CLOUD': {
    name: 'JIRA_CLOUD',
    hasFullLoadFrequency: true,
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#65A8FF',
    darkModePrimaryColor: '#65A8FF',
    docLink: '/sources/collaboration/jira/',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    configHelper: JiraConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AMPLITUDE_ANALYTICS': {
    name: 'AMPLITUDE_ANALYTICS',
    jobModes: [],
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#00A7CF',
    darkModePrimaryColor: '#00A7CF',
    docLink: '/sources/analytics/amplitude-analytics/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    showHistoricalLoadOptions: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: AmplitudeAnalyticsConfigHelper
  },
  'GOOGLE_CM': {
    name: 'GOOGLE_CM',
    testEnabled: true,
    requireAuth: true,
    authProvider: 'GOOGLE_DCM',
    isAuthTypeGoogle: true,
    primaryColor: '#5BB974',
    darkModePrimaryColor: '#5BB974',
    docLink: '/sources/marketing/gcm/',
    canValidateAdsAccount: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: GoogleCampaignManagerConfigHelperReact
  },
  'FACEBOOK_PAGES': {
    name: 'FACEBOOK_PAGES',
    requireAuth: true,
    authProvider: 'FACEBOOK_PAGES',
    authProviderClass: 'fb',
    authProviderIcon: 'https://res.cloudinary.com/hevo/image/upload/v1661186325/dashboard/fb-logo-white_aymaq1.svg',
    testEnabled: true,
    primaryColor: '#4267B2',
    darkModePrimaryColor: '#6393FF',
    docLink: '/sources/marketing/facebook-pages/',
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: FbInsightsConfigHelperReact,
    isHidden: typeof __HIDDEN_SOURCES__ !== 'undefined' && __HIDDEN_SOURCES__.FACEBOOK_PAGES
  },
  'KLAVIYO': {
    name: 'KLAVIYO',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/marketing/klaviyo/',
    primaryColor: '#232426',
    darkModePrimaryColor: '#FFFFFF',
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'KLAVIYO_V2': {
    name: 'KLAVIYO_V2',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/mkt-analytics/klaviyo-v2/',
    primaryColor: '#232426',
    darkModePrimaryColor: '#FFFFFF',
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'MOCK_LOAD': {
    name: 'MOCK_LOAD',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/marketing/mock-load/',
    primaryColor: 'rgb(75, 75, 75)',
    darkModePrimaryColor: '#27B4E1',
    beta: true,
    configHelper: MockLoadConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'OUTBRAIN': {
    name: 'OUTBRAIN',
    jobModes: [],
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#EE6513',
    darkModePrimaryColor: '#EE6513',
    docLink: '/sources/marketing/outbrain/',
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    configHelper: OutbrainConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ADROLL': {
    name: 'ADROLL',
    requireAuth: true,
    authProvider: 'ADROLL',
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#0EBBFE',
    darkModePrimaryColor: '#0EBBFE',
    docLink: '/sources/marketing/adroll/',
    selectObjects: false,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    configHelper: AdrollConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'LINKEDIN_ADS': {
    name: 'LINKEDIN_ADS',
    requireAuth: true,
    authProvider: 'LINKEDIN_ADS',
    testEnabled: true,
    primaryColor: '#006699',
    darkModePrimaryColor: '#006699',
    docLink: '/sources/marketing/linkedin-ads/',
    showHistoricalLoadOptions: true,
    selectObjects: true,
    canShowViewSchemaOption: true,
    configHelper: LinkedinAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PINTEREST_ADS': {
    name: 'PINTEREST_ADS',
    requireAuth: true,
    authProvider: 'PINTEREST_ADS',
    testEnabled: true,
    primaryColor: '#BD081C',
    darkModePrimaryColor: '#BD081C',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/marketing/pinterest-ads/',
    showHistoricalLoadOptions: true,
    selectObjects: true,
    canShowViewSchemaOption: true,
    configHelper: PinterestAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'CRITEO': {
    name: 'CRITEO',
    jobModes: [],
    canUseExisting: true,
    requireAuth: true,
    authProvider: 'CRITEO',
    testEnabled: true,
    primaryColor: '#F16C23',
    darkModePrimaryColor: '#F16C23',
    docLink: '/sources/marketing/criteo/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    configHelper: CriteoConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_RDS_MYSQL': {
    name: 'AWS_RDS_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/amazon-rds-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.AMAZON_RDS,
    parent: 'MYSQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'GCP_MYSQL': {
    name: 'GCP_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/gcloud-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.GOOGLE_CLOUD,
    parent: 'MYSQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AZURE_MYSQL': {
    name: 'AZURE_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/azure-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.AZURE,
    parent: 'MYSQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'ORACLE_MYSQL': {
    name: 'ORACLE_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.ORACLE,
    parent: 'MYSQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'TOKU_DB_MYSQL': {
    name: 'TOKU_DB_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.TOKUDB,
    parent: 'MYSQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AWS_RDS_MARIA_DB': {
    name: 'AWS_RDS_MARIA_DB',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/mariadb-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.AMAZON_RDS,
    parent: 'MARIA_DB',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AZURE_MARIA_DB': {
    name: 'AZURE_MARIA_DB',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.BINLOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mysql/mariadb-mysql/',
    primaryColor: 'rgb(255, 162, 48)',
    darkModePrimaryColor: '#E87912',
    selectObjects: true,
    variation: SqlVariations.AZURE,
    parent: 'MARIA_DB',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AWS_RDS_POSTGRES': {
    name: 'AWS_RDS_POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.LOGIC_REPL,
      JobModeEnum.XMIN,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/amazon-rds-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.AMAZON_RDS,
    parent: 'POSTGRES',
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AWS_AURORA_POSTGRES': {
    name: 'AWS_AURORA_POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.LOGIC_REPL,
      JobModeEnum.XMIN,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/amazon-aurora-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.AURORA,
    parent: 'POSTGRES',
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'GCP_POSTGRES': {
    name: 'GCP_POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.LOGIC_REPL,
      JobModeEnum.XMIN,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/gcloud-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.GOOGLE_CLOUD,
    parent: 'POSTGRES',
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AZURE_POSTGRES': {
    name: 'AZURE_POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.LOGIC_REPL,
      JobModeEnum.XMIN,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/azure-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.AZURE,
    parent: 'POSTGRES',
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'SF_HEROKU_POSTGRES': {
    name: 'SF_HEROKU_POSTGRES',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.TABLE,
      JobModeEnum.XMIN,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/postgresql/heroku-postgresql/',
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: SqlVariations.HEROKU,
    parent: 'POSTGRES',
    sslAllowed: true,
    sslCARequired: false,
    canSkipNewTables: true,
    configureObjects: isObjectConfigAllowedForPostgres,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'AWS_RDS_ORACLE': {
    name: 'AWS_RDS_ORACLE',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.REDO_LOG,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/oracle/amazon-rds-oracle/',
    primaryColor: 'rgb(255, 0, 0)',
    darkModePrimaryColor: '#FE0200',
    selectObjects: true,
    variation: SqlVariations.AMAZON_RDS,
    parent: 'ORACLE',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'AWS_RDS_MSSQL': {
    name: 'AWS_RDS_MSSQL',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.CHANGE_TRACKING,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mssql/amazon-rds-mssql/',
    selectObjects: true,
    variation: SqlVariations.AMAZON_RDS,
    parent: 'MS_SQL',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    primaryColor: 'rgb(255, 140, 155)',
    isSchemaNameRequired: true,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'GCP_MS_SQL': {
    name: 'GCP_MS_SQL',
    isJDBCType: true,
    provideSchemaName: true,
    jobModes: [
      JobModeEnum.CHANGE_TRACKING,
      JobModeEnum.TABLE,
      JobModeEnum.QUERY
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/dbfs/databases/mssql/gcloud-sql/',
    selectObjects: true,
    variation: SqlVariations.GOOGLE_CLOUD,
    parent: 'MS_SQL',
    canSkipNewTables: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    primaryColor: 'rgb(255, 140, 155)',
    isSchemaNameRequired: true,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true,
    canShowHelpNotes: true
  },
  'CONFLUENT_CLOUD': {
    name: 'CONFLUENT_CLOUD',
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#354D74',
    darkModePrimaryColor: '#FFFFFF',
    selectObjects: true,
    variation: KafkaVariations.CONFLUENT_CLOUD,
    parent: 'KAFKA',
    sslAllowed: false,
    sslCARequired: false,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/streaming/kafka/kafka-confluent-cloud/',
    configHelper: KafkaConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'YOUTUBE_ANALYTICS': {
    name: 'YOUTUBE_ANALYTICS',
    requireAuth: true,
    authProvider: 'YOUTUBE_ANALYTICS',
    testEnabled: true,
    primaryColor: '#FF0000',
    darkModePrimaryColor: '#FF0000',
    docLink: '/sources/analytics/youtube-analytics/',
    configHelper: YoutubeAnalyticsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'YOUTUBE_DATA': {
    name: 'YOUTUBE_DATA',
    testEnabled: false,
    requireAuth: true,
    authProvider: 'YOUTUBE_DATA',
    primaryColor: '#FF0000',
    darkModePrimaryColor: '#FF0000',
    docLink: '/sources/mkt-analytics/youtube-data/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PARDOT': {
    name: 'PARDOT',
    requireAuth: true,
    testEnabled: true,
    docLink: '/sources/marketing/pardot/',
    primaryColor: '#00A3E6',
    darkModePrimaryColor: '#00A1E0',
    authProvider: 'PARDOT',
    selectObjects: true,
    authAccountsEnvironmentsMetaData: {
      [AuthAccountEnvironment.PRODUCTION]: {
        label: 'Production',
        description: 'The environment where live business data is stored'
      },
      [AuthAccountEnvironment.TESTING]: {
        label: 'Sandbox',
        description: 'An isolated environment used for development and testing'
      }
    },
    showHistoricalLoadOptions: true,
    linkedAccount: 'Salesforce',
    canShowViewSchemaOption: true,
    configHelper: PardotConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SALESFORCE_MARKETING_CLOUD': {
    name: 'SALESFORCE_MARKETING_CLOUD',
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#00A3E6',
    darkModePrimaryColor: '#00A1E0',
    docLink: '/sources/marketing/sfmc/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    configHelper: SalesforceMarketingCloudConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'APPLE_SEARCH_ADS': {
    name: 'APPLE_SEARCH_ADS',
    testEnabled: true,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFFFFF',
    docLink: '/sources/marketing/apple-search-ads/',
    showHistoricalLoadOptions: true,
    canUseExisting: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    canShowViewSchemaOption: true,
    configHelper: AppleSearchAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_ELASTICSEARCH': {
    name: 'AWS_ELASTICSEARCH',
    jobModes: [ JobModeEnum.ELASTICSEARCH ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/databases/elasticsearch/',
    primaryColor: '#00c1b1',
    darkModePrimaryColor: '#00c1b1',
    selectObjects: true,
    configureObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: ElasticSearchVariations.AWS_ELASTICSEARCH,
    canSkipNewTables: true,
    parent: 'ELASTICSEARCH',
    configHelper: ElasticSearchConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GOOGLE_ANALYTICS_360': {
    name: 'GOOGLE_ANALYTICS_360',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'GOOGLE_ANALYTICS_360',
    isAuthTypeGoogle: true,
    primaryColor: 'rgb(243, 109, 0)',
    darkModePrimaryColor: '#E37400',
    docLink: '/sources/analytics/google-analytics-360/',
    showHistoricalLoadOptions: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    linkedAccount: 'BigQuery',
    canShowViewSchemaOption: true,
    configHelper: GoogleAnalytics360ConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'INSTAGRAM_BUSINESS': {
    name: 'INSTAGRAM_BUSINESS',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'INSTAGRAM_BUSINESS',
    docLink: '/sources/marketing/instagram-business/',
    primaryColor: '#E1306C',
    darkModePrimaryColor: '#E1306C',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    configHelper: InstagramBusinessConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    isHidden: typeof __HIDDEN_SOURCES__ !== 'undefined' && __HIDDEN_SOURCES__.INSTAGRAM_BUSINESS
  },
  'BIGQUERY': {
    name: 'BIGQUERY',
    requireServiceAuth: true,
    testEnabled: true,
    authProvider: 'BIGQUERY_SOURCE',
    isAuthTypeGoogle: true,
    primaryColor: 'rgb(69, 134, 247)',
    darkModePrimaryColor: '#6FAAFF',
    docLink: '/sources/dbfs/data-warehouses/google-bigquery/',
    canSkipNewTables: true,
    selectObjects: true,
    configHelper: BigQuerySourceConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'XERO': {
    name: 'XERO',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'XERO',
    primaryColor: '#13B5EA',
    darkModePrimaryColor: '#13B5EA',
    docLink: '/sources/finance-&-accounting/xero/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    configHelper: XeroConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    isHidden: typeof __HIDDEN_SOURCES__ !== 'undefined' && __HIDDEN_SOURCES__.XERO
  },
  'GOOGLE_ANALYTICS_4': {
    name: 'GOOGLE_ANALYTICS_4',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'GOOGLE_ANALYTICS_4',
    isAuthTypeGoogle: true,
    primaryColor: 'rgb(243, 109, 0)',
    darkModePrimaryColor: '#E37400',
    docLink: '/sources/analytics/google-analytics-4/',
    showHistoricalLoadOptions: true,
    configHelper: GoogleAnalytics4ConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TABOOLA': {
    name: 'TABOOLA',
    canUseExisting: true,
    testEnabled: true,
    primaryColor: '#0071B7',
    darkModePrimaryColor: '#0071B7',
    docLink: '/sources/marketing/taboola/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: TaboolaConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FIREBASE_ANALYTICS': {
    name: 'FIREBASE_ANALYTICS',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'FIREBASE_ANALYTICS',
    isAuthTypeGoogle: true,
    primaryColor: '#FFCB2D',
    darkModePrimaryColor: '#FFCB2D',
    docLink: '/sources/analytics/google-analytics-4/',
    showHistoricalLoadOptions: true,
    configHelper: FirebaseAnalyticsConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PIPEDRIVE': {
    name: 'PIPEDRIVE',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'PIPEDRIVE',
    authAccountLabel: 'API Domain',
    primaryColor: '#25292C',
    darkModePrimaryColor: '#FFFFFF',
    docLink: '/sources/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'ASANA': {
    name: 'ASANA',
    requireAuth: true,
    testEnabled: true,
    authProvider: 'ASANA',
    primaryColor: '#F95873',
    darkModePrimaryColor: '#F95873',
    docLink: '/sources/collaboration/asana/',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    configHelper: AsanaConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'WORDPRESS_VIA_MYSQL': {
    name: 'WORDPRESS_VIA_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.TABLE
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/e-commerce/wordpress/',
    primaryColor: '#49A3C2',
    darkModePrimaryColor: '#49A3C2',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'WOOCOMMERCE_VIA_MYSQL': {
    name: 'WOOCOMMERCE_VIA_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.TABLE
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/e-commerce/woocommerce/',
    primaryColor: '#7F54B3',
    darkModePrimaryColor: '#7F54B3',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'MAGENTO_VIA_MYSQL': {
    name: 'MAGENTO_VIA_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.TABLE
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/e-commerce/magento/',
    primaryColor: '#EC6737',
    darkModePrimaryColor: '#EC6737',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'PRESTASHOP_VIA_MYSQL': {
    name: 'PRESTASHOP_VIA_MYSQL',
    isJDBCType: true,
    jobModes: [
      JobModeEnum.TABLE
    ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/e-commerce/prestashop/',
    primaryColor: '#A5DBE8',
    darkModePrimaryColor: '#A5DBE8',
    selectObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    sslAllowed: true,
    sslCARequired: true,
    canSkipNewTables: true,
    configureObjects: shouldAllowObjectsConfigurationForJDBCSources,
    canDiagnoseConnectionErrors: true,
    configHelper: SqlConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    canDisableFormSubmitCTAsForInvalidForm: true
  },
  'GOOGLE_ADWORDS': {
    name: 'GOOGLE_ADWORDS',
    requireAuth: true,
    docLink: '/sources/marketing/google-ads/google-adwords/',
    primaryColor: 'rgb(59, 126, 235)',
    darkModePrimaryColor: '#4285F4',
    selectObjects: true,
    configureObjects: false,
    authProvider: 'GOOGLE_ADWORDS',
    isAuthTypeGoogle: true,
    showHistoricalLoadOptions: true,
    configHelper: null
  },
  'GOOGLE_ADS': {
    name: 'GOOGLE_ADS',
    requireAuth: true,
    docLink: '/sources/marketing/google-ads/google-ads-api/',
    primaryColor: 'rgb(59, 126, 235)',
    darkModePrimaryColor: '#4285F4',
    selectObjects: true,
    configureObjects: false,
    authProvider: 'GOOGLE_ADWORDS',
    isAuthTypeGoogle: true,
    canValidateAdsAccount: true,
    showHistoricalLoadOptions: true,
    configHelper: GoogleAdsReactConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    pipelineSchedulerBasedDefaultFrequency: {
      frequencyValue: 12,
      frequencyType: FREQUENCY_TYPE_HOURS
    }
  },
  'QUICKBOOKS': {
    name: 'QUICKBOOKS',
    requireAuth: true,
    authProvider: 'QUICKBOOKS',
    testEnabled: true,
    primaryColor: '#2CA01C',
    darkModePrimaryColor: '#2CA01C',
    selectObjects: true,
    docLink: '/sources/finance-&-accounting/qbo/',
    showHistoricalLoadOptions: true,
    configHelper: null,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'SNAPCHAT_ADS': {
    name: 'SNAPCHAT_ADS',
    testEnabled: true,
    requireAuth: true,
    docLink: '/sources/marketing/snapchat-ads/',
    primaryColor: '#FFFC00',
    darkModePrimaryColor: '#FFFC00',
    selectObjects: true,
    authProvider: 'SNAPCHAT_ADS',
    showHistoricalLoadOptions: true,
    hasFullLoadFrequency: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: SnapchatAdsConfigHelper
  },
  'CHARGEBEE': {
    name: 'CHARGEBEE',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/finance-&-accounting/chargebee/',
    primaryColor: '#FF6C36',
    darkModePrimaryColor: '#FF6C36',
    canShowViewSchemaOption: true,
    selectObjects: true,
    configHelper: ChargebeeConfigHelper,
    showHistoricalLoadOptions: true,
    formRenderingLibrary: ViewLibrary.REACT,
    beta: true
  },
  'DRIP': {
    name: 'DRIP',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#CD1ECD',
    darkModePrimaryColor: '#CD1ECD',
    docLink: '/sources/e-commerce/drip/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'INVOICED': {
    name: 'INVOICED',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#0F7162',
    darkModePrimaryColor: '#0F7162',
    docLink: '/sources/finance-&-accounting/invoiced/',
    beta: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AFTERSHIP': {
    name: 'AFTERSHIP',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#FEA500',
    darkModePrimaryColor: '#FEA500',
    docLink: '/sources/e-commerce/aftership/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BRAINTREE_PAYMENTS': {
    name: 'BRAINTREE_PAYMENTS',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/finance-&-accounting/braintree-payments/',
    primaryColor: '#FFFFFF',
    darkModePrimaryColor: '#000000',
    canShowViewSchemaOption: true,
    selectObjects: true,
    configHelper: BraintreePaymentsConfigHelper,
    showHistoricalLoadOptions: true,
    beta: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'RECURLY': {
    name: 'RECURLY',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/finance-&-accounting/recurly/',
    primaryColor: '#723080',
    darkModePrimaryColor: '#723080',
    canShowViewSchemaOption: true,
    selectObjects: true,
    configHelper: RecurlyConfigHelper,
    showHistoricalLoadOptions: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'RECURLY_V2': {
    name: 'RECURLY_V2',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/finance-&-accounting/recurly/',
    primaryColor: '#723080',
    darkModePrimaryColor: '#FFFFFF',
    canShowViewSchemaOption: true,
    selectObjects: false,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true
  },
  'FRONT': {
    name: 'FRONT',
    testEnabled: true,
    docLink: '/sources/marketing/front/',
    primaryColor: '#FF0057',
    darkModePrimaryColor: '#FF0057',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    configHelper: FrontConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TYPEFORM': {
    name: 'TYPEFORM',
    testEnabled: true,
    requireAuth: true,
    authAccountLabel: 'Authorized Account',
    authProvider: 'TYPEFORM',
    docLink: '/sources/marketing/typeform/',
    primaryColor: '#262627',
    darkModePrimaryColor: '#FFFFFF',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    configHelper: TypeformConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'TYPEFORM_II': {
    name: 'TYPEFORM_II',
    testEnabled: false,
    requireAuth: true,
    authAccountLabel: 'Authorized Account',
    authProvider: 'TYPEFORM_II',
    docLink: '/sources/marketing/typeform/',
    primaryColor: '#262627',
    darkModePrimaryColor: '#FFFFFF',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PENDO': {
    name: 'PENDO',
    testEnabled: true,
    docLink: '/sources/analytics/pendo/',
    primaryColor: '#EC1F5A',
    darkModePrimaryColor: '#EC1F5A',
    selectObjects: true,
    showHistoricalLoadOptions: true,
    canUseExisting: true,
    beta: true,
    configHelper: PendoConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    canShowViewSchemaOption: true
  },
  'TWILIO': {
    name: 'TWILIO',
    testEnabled: true,
    docLink: '/sources/marketing/twilio/',
    primaryColor: '#CF272D',
    darkModePrimaryColor: '#CF272D',
    selectObjects: true,
    configHelper: TwilioConfigHelper,
    showHistoricalLoadOptions: true,
    canShowViewSchemaOption: true,
    formRenderingLibrary: ViewLibrary.REACT,
    canUseExisting: true
  },
  'TIKTOK_ADS': {
    name: 'TIKTOK_ADS',
    testEnabled: true,
    requireAuth: true,
    docLink: '/sources/marketing/tiktok-ads/',
    primaryColor: '#69C9D0',
    darkModePrimaryColor: '#69C9D0',
    selectObjects: true,
    authProvider: 'TIKTOK_ADS',
    showHistoricalLoadOptions: true,
    configHelper: TiktokAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SHIPSTATION': {
    name: 'SHIPSTATION',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#80BB56',
    darkModePrimaryColor: '#80BB56',
    docLink: '/sources/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'STAMPED': {
    name: 'STAMPED',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#FF672E',
    darkModePrimaryColor: '#FFD600',
    docLink: '/sources/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SWELL': {
    name: 'SWELL',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#F97382',
    darkModePrimaryColor: '#F97382',
    docLink: '/sources/e-commerce/swell/',
    beta: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'DELIGHTED': {
    name: 'DELIGHTED',
    testEnabled: true,
    canUseExisting: false,
    primaryColor: '#000000',
    darkModePrimaryColor: '#FFFFFF',
    docLink: '/sources/marketing/delighted/',
    beta: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_DOCUMENT_DB': {
    name: 'AWS_DOCUMENT_DB',
    canUseExisting: true,
    testEnabled: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/databases/amazon-documentdb/',
    primaryColor: '#F06939',
    selectObjects: true,
    configureObjects: false,
    darkModePrimaryColor: '#F06939',
    canSkipNewTables: true,
    sslCARequired: false,
    configHelper: AwsDocumentdbConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT,
    beta: true
  },
  'BIGCOMMERCE': {
    name: 'BIGCOMMERCE',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/prod-analytics/bigcommerce/',
    primaryColor: '#34313F',
    darkModePrimaryColor: '#FFFFFF',
    showHistoricalLoadOptions: true,
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true,
    canShowSideBySideDocs: true,
    selectObjects: true
  },
  'CONTENTFUL': {
    name: 'CONTENTFUL',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/',
    primaryColor: '#34313F',
    darkModePrimaryColor: '#FFFFFF',
    formRenderingLibrary: ViewLibrary.REACT,
    genericReactComponent: true,
    beta: true
  },
  'QUICKBOOKS_TIME': {
    name: 'QUICKBOOKS_TIME',
    testEnabled: true,
    primaryColor: '#1F8440',
    darkModePrimaryColor: '#1F8440',
    docLink: '/sources/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT,
    beta: true
  },
  'USERVOICE': {
    name: 'USERVOICE',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#FF8600',
    darkModePrimaryColor: '#FF8600',
    docLink: '/sources/analytics/uservoice/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ACTIVE_CAMPAIGN': {
    name: 'ACTIVE_CAMPAIGN',
    testEnabled: true,
    canUseExisting: true,
    primaryColor: '#356AE6',
    darkModePrimaryColor: '#356AE6',
    docLink: '/sources/marketing/activecampaign/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BRAZE': {
    name: 'BRAZE',
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/',
    canShowSideBySideDocs: false,
    primaryColor: '#212124',
    darkModePrimaryColor: '#FFFFFF',
    configHelper: BrazeConfigHelper,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'HELPSCOUT': {
    name: 'HELPSCOUT',
    testEnabled: true,
    requireAuth: true,
    docLink: '/sources/crm/helpscout/',
    primaryColor: '#1292ee',
    darkModePrimaryColor: '#FFFFFF',
    authProvider: 'HELPSCOUT',
    beta: true,
    showHistoricalLoadOptions: true,
    selectObjects: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AZURE_BLOB': {
    name: 'AZURE_BLOB',
    beta: true,
    canUseExisting: true,
    testEnabled: false,
    isFolderSelectorType: true,
    docLink: '/sources/dbfs/file-storage/azure-blob-storage/',
    primaryColor: '#EC1F5A',
    darkModePrimaryColor: '#EC1F5A',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    configHelper: AzureBlobConfigHelperReact,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'NETSUITE_ERP': {
    name: 'NETSUITE_ERP',
    testEnabled: true,
    requireAuth: false,
    docLink: '/sources/fna-analytics/netsuite-erp/',
    canShowSideBySideDocs: false,
    primaryColor: '#1292ee',
    darkModePrimaryColor: '#FFFFFF',
    beta: true,
    showHistoricalLoadOptions: true,
    selectObjects: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SURVEY_MONKEY': {
    name: 'SURVEY_MONKEY',
    testEnabled: true,
    requireAuth: true,
    docLink: '/sources/',
    primaryColor: '#00BD70',
    darkModePrimaryColor: '#00BD70',
    authProvider: 'SURVEY_MONKEY',
    beta: true,
    showHistoricalLoadOptions: true,
    selectObjects: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'MAILSHAKE': {
    name: 'MAILSHAKE',
    testEnabled: true,
    canShowSideBySideDocs: true,
    docLink: '/sources/',
    showSBSTabs: true,
    primaryColor: '#2E71B8',
    darkModePrimaryColor: '#ED1A5F',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'CLICKUP': {
    name: 'CLICKUP',
    testEnabled: true,
    primaryColor: '#7B68EE',
    darkModePrimaryColor: '#7B68EE',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    docLink: '/sources/sns-analytics/clickup/',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'VINCERE': {
    name: 'VINCERE',
    testEnabled: true,
    requireAuth: true,
    authProvider: 'VINCERE',
    primaryColor: '#333333',
    darkModePrimaryColor: '#333333',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PANDA_DOC': {
    name: 'PANDA_DOC',
    testEnabled: true,
    primaryColor: '#4AB877',
    darkModePrimaryColor: '#4AB877',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SPARK_POST': {
    name: 'SPARK_POST',
    testEnabled: true,
    primaryColor: '#FA6423',
    darkModePrimaryColor: '#FA6423',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'QUANTIVE': {
    name: 'QUANTIVE',
    testEnabled: true,
    primaryColor: '#15C679',
    darkModePrimaryColor: '#15C679',
    beta: true,
    docLink: '/sources/prod-analytics/quantive/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'STREAK': {
    name: 'STREAK',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#F75050',
    darkModePrimaryColor: '#F75050',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SLACK': {
    name: 'SLACK',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#4a154b',
    darkModePrimaryColor: '#4a154b',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'HIVE': {
    name: 'HIVE',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#FFA561',
    darkModePrimaryColor: '#FFA561',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AMAZON_ADS': {
    name: 'AMAZON_ADS',
    requireAuth: true,
    privateBeta: true,
    canShowSourceVersionStep: false,
    docLink: '/sources/',
    darkModePrimaryColor: 'rgba(247, 165, 30, 0.15)',
    primaryColor: 'rgba(247, 165, 30, 0.10)',
    selectObjects: true,
    configureObjects: false,
    authProvider: 'AMAZON_ADS',
    authProviderClass: 'amazon',
    authProviderIcon: 'https://res.cloudinary.com/hevo/image/upload/v1673449978/dashboard/amazon-icon_ilwaqn.svg',
    canValidateAdsAccount: true,
    showHistoricalLoadOptions: true,
    hasFullLoadFrequency: true,
    configHelper: AmazonAdsConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT,
    pipelineSchedulerBasedDefaultFrequency: {
      frequencyValue: 12,
      frequencyType: FREQUENCY_TYPE_HOURS
    }
  },
  'GITLAB': {
    name: 'GITLAB',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#E24328',
    darkModePrimaryColor: '#E24328',
    beta: true,
    genericReactComponent: true,
    requireServiceAuth: false,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'PRODUCTBOARD': {
    name: 'PRODUCTBOARD',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#0179F2',
    darkModePrimaryColor: '#0179F2',
    beta: true,
    docLink: '/sources/prod-analytics/prodboard/',
    genericReactComponent: true,
    requireServiceAuth: false,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'WRIKE': {
    name: 'WRIKE',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#03CF65',
    darkModePrimaryColor: '#03CF65',
    beta: true,
    docLink: '/sources/sns-analytics/wrike/',
    genericReactComponent: true,
    requireServiceAuth: false,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BUILDKITE': {
    name: 'BUILDKITE',
    testEnabled: true,
    requireAuth: false,
    primaryColor: '#2EF2A1',
    darkModePrimaryColor: '#2EF2A1',
    beta: true,
    genericReactComponent: true,
    requireServiceAuth: false,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FRESH_SALES': {
    name: 'FRESH_SALES',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(229, 115, 0, .1)',
    darkModePrimaryColor: 'rgba(229, 115, 0, .15)',
    beta: true,
    docLink: '/sources/mkt-analytics/freshsales/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FRESH_SERVICE': {
    name: 'FRESH_SERVICE',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(20, 143, 204, .1)',
    darkModePrimaryColor: 'rgba(20, 143, 204, .15)',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SENTRY': {
    name: 'SENTRY',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(94, 69, 118, .1)',
    darkModePrimaryColor: 'rgba(94, 69, 118, .15)',
    beta: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'HUB_PLANNER': {
    name: 'HUB_PLANNER',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(85, 199, 167, .1)',
    darkModePrimaryColor: 'rgba(85, 199, 167, .15)',
    beta: true,
    docLink: '/sources/sns-analytics/hubplanner/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'APIFY': {
    name: 'APIFY',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(255, 120, 5, .1)',
    darkModePrimaryColor: 'rgba(255, 120, 5, .15)',
    beta: true,
    docLink: '/sources/engg-analytics/apify/',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'NETSUITE_ANALYTICS': {
    name: 'NETSUITE_ANALYTICS',
    testEnabled: true,
    requireAuth: false,
    docLink: '/sources/fna-analytics/suiteanalytics/',
    canShowSideBySideDocs: false,
    primaryColor: '#1292ee',
    darkModePrimaryColor: '#FFFFFF',
    beta: true,
    showHistoricalLoadOptions: false,
    selectObjects: true,
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT,
    hasFullLoadFrequency: true,
    canSkipNewTables: true,
  },
  'AWS_OPENSEARCH': {
    name: 'AWS_OPENSEARCH',
    jobModes: [ JobModeEnum.ELASTICSEARCH ],
    canUseExisting: true,
    testEnabled: true,
    docLink: '/sources/databases/elasticsearch/',
    primaryColor: '#005EB8',
    darkModePrimaryColor: '#00A3E0',
    selectObjects: true,
    configureObjects: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    variation: ElasticSearchVariations.AWS_OPENSEARCH,
    canSkipNewTables: true,
    parent: 'ELASTICSEARCH',
    configHelper: ElasticSearchConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SALESLOFT': {
    name: 'SALESLOFT',
    testEnabled: true,
    requireAuth: false,
    primaryColor: 'rgba(54, 166, 53, .1)',
    darkModePrimaryColor: 'rgba(54, 166, 53, .15)',
    beta: true,
    docLink: '/sources',
    genericReactComponent: true,
    formRenderingLibrary: ViewLibrary.REACT
  }
};

export function shouldAllowObjectsConfigurationForMongodb(source: any, jobMode: JobMode) {
  return !(jobMode.historicalMode && !source.importHistoricalData);
}

export function shouldAllowObjectsConfigurationForJDBCSources(source: any, jobMode: JobMode) {
  return !(
    (jobMode.historicalMode || source.showImportHistoricalLoadForTableMode)
    && !source.importHistoricalData
  );
}

export function isObjectConfigAllowedForPostgres(source: any, jobMode: JobMode) {
  if (jobMode.value === JobModeEnum.XMIN.value) {
    return false;
  }

  return shouldAllowObjectsConfigurationForJDBCSources(source, jobMode);
}

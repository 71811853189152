/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef, useCallback, forwardRef } from 'react';
import { Box, Popper, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions, autocompleteClasses } from '@mui/material/Autocomplete';
import clsx from 'clsx';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import useForceUpdate from '../../../hooks/useForceUpdate';
import { useVisibleOnScreen } from '../../../hooks/useVisibleOnScreen';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdTextField } from '../HdTextField';
import HdIcon from '../HdIcon';
import { HdAutocompletePaper } from './elements';
import styles from './styles.module.scss';
import HdTag from '../HdTag';
import { HdCheckbox } from '../HdCheckbox';
import { HdTooltip } from '../HdTooltip';

const LIST_DEFAULT_ITEM_HEIGHT = 40;
export const SELECT_ALL_ID = 'select-all';
const selectAll: OptionsType = { name: 'Select All', id: SELECT_ALL_ID };

export const accessorFn = (accessor, defaultAccessor) => {
  let fn = accessor;
  if (typeof accessor === 'string') {
    fn = ele => ele?.[accessor];
  }

  /**
   * to handle internal options like select all, etc
   * handles strings as an option
   */
  return option =>
    fn(option) || option?.[defaultAccessor] || (typeof option === 'string' && option);
};

export interface OptionsType {
  name: string;
  id: string;
  category?: string;
  disabled?: boolean;
  [key: string]: any;
}

interface HdDropDownOptionProps {
  customOptionWrapperClass?: Function;
  option: OptionsType | Array<OptionsType> | any;
  selected: boolean;
  hoverOptionRef: any;
  setHoverOption: Function;
  inputValue?: string;
  disabledOptions?: Map<String, boolean>;
  displayAccessorFn: Function;
  valueAccessorFn: Function;
  CustomOption?: React.FC<{
    option: OptionsType;
    onClose: Function;
    isSelectedOption: boolean;
  }>;
  OptionTooltipComponent?: (option: OptionsType, isOptionDisabled: boolean) => string | null;
  onClose: Function;
  disabledDropdown?: boolean;
  multiple?: boolean;
  getSelectedOption: Function;
  allSelected?: boolean;
  id?: string;
  dataIdGenerator: Function;
  onClick?: Function;
}

interface NoOptionsComponentProps {
  onClick: Function;
  inputText: string;
  options: OptionsType[];
}

export interface HdDropDownProps {
  customOptionWrapperClass?: Function;
  dataId: string;
  options: Array<OptionsType | any>;
  label?: string;
  placeholder?: string;
  id: string;
  helperText?: React.ReactNode;
  className?: string;
  paperClasses?: string;
  TopAdornment?: React.FC<any>;
  BottomAdornment?: React.FC<any>;
  onChangeEventHandler: Function;
  CustomOption?: React.FC<{
    option: OptionsType;
    onClose?: Function;
    isSelectedOption?: boolean;
  }>;
  prefixElement?: React.ReactElement;
  onClose?: Function;
  disableVirtualization?: boolean;
  helperAdornmentDirection?: 'left' | 'right';
  prefixIcon?: string;
  prefixText?: any;
  suffixText?: string;
  creatable?: boolean;
  creatableLabel?: string;
  createNewOptions?: boolean;
  creatableCallback?: Function;
  creatableInputValue?: string;
  creatableHelperText?: string;
  creatableFieldShowError?: boolean;
  onCreatableInputSelection?: Function;
  onCreatableInputChangeHandler?: Function;
  creatableBlurHandler?: Function;
  createOnClickAway?: boolean;
  selected?: OptionsType | any;
  group?: boolean;
  multiple?: boolean;
  HelperDocumentAdornment?: React.FC<any>;
  helperDocumentAdornmentClass?: string;
  hideClearable?: boolean;
  topAdornmentProps?: object;
  bottomAdornmentProps?: object;
  SelectedOptionRenderInSelectMode?: React.FC<any>;
  renderSelectAll?: boolean;
  noOptionsComponentProps?: any;
  NoOptionsComponent?: React.FC<NoOptionsComponentProps>;
  noOptionsText?: string;
  disabled?: boolean;
  inputFieldProps?: object;
  classes?: object;
  onFocus?: Function;
  showLoading?: boolean;
  disabledOptions?: Map<string, boolean>;
  suffixElement?: React.ReactElement;
  autoWidthPaperMode?: boolean;
  required?: boolean;
  displayAccessor?: string | Function;
  valueAccessor?: string | Function;
  error?: boolean;
  hoveredOption?: OptionsType;
  asyncMethod?: Function;
  onOpen?: Function;
  classStyles?: {
    'MuiOutlinedInput-root'?: any;
  };
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  size?: 'small' | 'medium';
  sortSelected?: boolean;
  selectMode?: boolean;
  asyncSearch?: boolean;
  onSearch?: any;
  bottomScrollSentryRef?: any;
  InputLabelProps?: any;
  onHoverOption?: Function;
  GroupComponent?: React.FC;
  InputComponent?: React.FC;
  OptionTooltipComponent?: (option: OptionsType, isOptionDisabled: boolean) => string | null;
  PopperComponent?: React.FC;
  renderInline?: boolean;
  filterOptionsCallback?: Function;
  sortSelectedOptions?: boolean;
  resetInputValue?: number;
  inputValueChangeCallback?: Function;
  searchableInput?: boolean;
  displayValueFn?: Function;
  creatablePrimitive?: boolean;
  sortDisabledOptions?: boolean;
  popperContentClassName?: string;
}

const sanitizeSelectedValue = (selected, multiple) => (multiple && !selected ? [] : selected);

export default function HdDropDown({
  dataId,
  options = [],
  label,
  placeholder,
  id,
  className,
  paperClasses,
  helperText,
  hideClearable = false,
  onChangeEventHandler,
  CustomOption,
  TopAdornment,
  BottomAdornment,
  suffixText,
  suffixElement,
  prefixElement,
  prefixIcon,
  prefixText,
  OptionTooltipComponent,
  topAdornmentProps,
  noOptionsComponentProps = {},
  NoOptionsComponent,
  bottomAdornmentProps,
  noOptionsText = 'No options found.',
  SelectedOptionRenderInSelectMode,
  selected = null,
  group = false,
  classes = {},
  inputFieldProps = {},
  onClose,
  multiple = false,
  renderSelectAll = false,
  error = false,
  onOpen,
  onBlur,
  HelperDocumentAdornment,
  helperAdornmentDirection,
  helperDocumentAdornmentClass,
  valueAccessor = ele => ele?.id,
  displayAccessor = ele => ele?.name,
  required = false,
  disabled = false,
  showLoading = false,
  onFocus,
  size,
  disabledOptions,
  disableVirtualization,
  autoWidthPaperMode = false,
  sortSelected = true,
  selectMode = false,
  asyncMethod,
  asyncSearch,
  hoveredOption,
  onHoverOption,
  onSearch,
  classStyles,
  bottomScrollSentryRef,
  InputLabelProps,
  GroupComponent,
  InputComponent,
  PopperComponent,
  renderInline = false,
  filterOptionsCallback,
  resetInputValue,
  inputValueChangeCallback,
  searchableInput = false,
  customOptionWrapperClass,
  sortDisabledOptions = false,
  sortSelectedOptions = false,
  popperContentClassName
}: HdDropDownProps) {
  const forceUpdate = useForceUpdate();
  const topAdornmentPropsRef = useRef(topAdornmentProps);
  const bottomAdornmentPropsRef = useRef(bottomAdornmentProps);
  const valueAccessorFn = accessorFn(valueAccessor, 'id');
  const displayAccessorFn = accessorFn(displayAccessor, 'name');
  const defaultValue = multiple && !selected ? [] : selected;
  const [selectedOption, setSelectedOption] = useState<OptionsType | Array<OptionsType> | null>(
    defaultValue
  );
  const [optionsList, setOptionsList] = useState<Array<OptionsType>>(options);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState(showLoading);
  const [inputValue, setInputValue] = useState('');
  const [allSelected, setAllSelected] = useState(
    multiple && defaultValue ? defaultValue.length === options.length : false
  );
  const dropDownRef = useRef(null);
  const filteredList = useRef<Array<OptionsType>>([]);
  const isVisible = useVisibleOnScreen(dropDownRef);

  const hoverOptionRef = useRef(null);

  const setHoverOption = option => {
    hoverOptionRef.current = option;

    if (HelperDocumentAdornment) {
      forceUpdate();
    }

    if (HelperDocumentAdornment && typeof onHoverOption === 'function') {
      onHoverOption(option);
    }
  };

  let conditionalProps = {};

  if (group) {
    conditionalProps = { ...conditionalProps, groupBy: option => (group ? option.category : null) };
  }

  if (multiple || searchableInput) {
    conditionalProps = { ...conditionalProps, inputValue };
  }

  if (GroupComponent) {
    conditionalProps = { ...conditionalProps, renderGroup: GroupComponent };
  }

  useEffect(() => {
    if (hoverOptionRef.current !== hoveredOption) {
      setHoverOption(hoveredOption);
    }
  }, [hoveredOption]);

  useEffect(() => {
    if (sortDisabledOptions) {
      const sortedOptions = options.sort((a, b) => Number(a.disabled) - Number(b.disabled));
      setOptionsList(sortedOptions);

      return;
    }
    setOptionsList(options);
    sortOptionsOnMount();
  }, [options]);

  useDontMountAtFirst(() => {
    if (!isOpened && onClose) {
      onClose();
    }
  }, [isOpened]);

  useEffect(() => {
    if (disabledOptions) {
      const sortedOptions = options.sort(
        (a, b) =>
          Number(disabledOptions.get(a.value) || false) -
          Number(disabledOptions.get(b.value) || false)
      );
      setOptionsList(sortedOptions);
    }
  }, [disabledOptions]);

  useEffect(() => {
    if (isOpened && !isVisible) {
      setIsOpened(false);
    }
  }, [isVisible, isOpened]);

  useEffect(() => {
    setLoading(showLoading);
  }, [showLoading]);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [selected]);

  useEffect(() => {
    if (sortSelected) {
      sortOptions();
    }
  }, [selectedOption]);

  useEffect(() => {
    topAdornmentPropsRef.current = topAdornmentProps;
    forceUpdate();
  }, [topAdornmentProps]);

  useEffect(() => {
    bottomAdornmentPropsRef.current = bottomAdornmentProps;
    forceUpdate();
  }, [bottomAdornmentProps]);

  useEffect(() => {
    if (!asyncMethod) {
      return;
    }

    let active = true;

    if ((!isOpened && !multiple && !renderInline) || optionsList.length !== 0) {
      return;
    }

    (async () => {
      if (active) {
        try {
          setLoading(true);
          await asyncMethod();
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [isOpened]);

  /**
   * listening change in filtered options list to sync the state of select all
   */
  useEffect(() => {
    if (renderSelectAll && filteredList.current.length > 0) {
      const containsAllOptions = filteredList.current.every(element => {
        const elementId = valueAccessorFn(element);
        return selectedOption.find(
          selectedElement => valueAccessorFn(selectedElement) === elementId
        );
      });

      setAllSelected(containsAllOptions);
    } else {
      setAllSelected(false);
    }
  }, [filteredList.current, optionsList, selectedOption, isOpened]);

  useEffect(() => {
    if (resetInputValue) {
      setInputValue('');
    }
  }, [resetInputValue]);

  const isOptionDisabled = (option: OptionsType) => option.disabled;

  const sortOptionsOnMount = () => {
    if (sortSelectedOptions && multiple && Array.isArray(selectedOption)) {
      const disabledSelectedOptions = selectedOption.filter(isOptionDisabled);
      const nonDisabledSelectedOptions = selectedOption.filter(option => !isOptionDisabled(option));

      const nonDisabledNonSelectedOptions: OptionsType[] = [];
      const disabledNonSelectedOptions: OptionsType[] = [];

      options.forEach((option: OptionsType) => {
        if (option.disabled && !selectedOption.find(item => item.id === option.id)) {
          disabledNonSelectedOptions.push(option);
        }

        if (!option.disabled && !selectedOption.find(item => item.id === option.id)) {
          nonDisabledNonSelectedOptions.push(option);
        }
      });

      const sortedOptions = [
        ...disabledSelectedOptions,
        ...nonDisabledSelectedOptions,
        ...nonDisabledNonSelectedOptions,
        ...disabledNonSelectedOptions
      ];

      setOptionsList(sortedOptions);
    }
  };

  /**
   * function to rearrange the options to put the selected ones always on top
   * along with the group if it's enabled
   */
  const sortOptions = () => {
    if (!sortSelectedOptions || multiple || Array.isArray(selectedOption)) {
      return;
    }

    if (!selectedOption) {
      setOptionsList(options);
      return;
    }

    let sortedOptions;

    const filteredSelectedOption = options.find(
      item => valueAccessorFn(item) === valueAccessorFn(selectedOption)
    );

    if (group) {
      const selectedOptions = [];

      const nonSelectedOptions = options.filter(item => {
        if (item.category === selectedOption.category) {
          selectedOptions.push(item);
        }
        return item.category !== selectedOption.category;
      });

      const remainingSelectedOptions = selectedOptions.filter(
        item => valueAccessorFn(item) !== valueAccessorFn(selectedOption)
      );

      sortedOptions = [...remainingSelectedOptions, ...nonSelectedOptions];
    } else {
      const nonSelectedOptions = options.filter(
        item => valueAccessorFn(item) !== valueAccessorFn(selectedOption)
      );

      sortedOptions = [...nonSelectedOptions];
    }

    if (filteredSelectedOption) {
      sortedOptions = [filteredSelectedOption, ...sortedOptions];
    }

    setOptionsList(sortedOptions);
  };

  /**
   * function to filter the searched options
   * overriding to enable searching with the sub text
   */
  const filter = createFilterOptions({
    ignoreCase: true,
    stringify: (option: OptionsType) => {
      let out = displayAccessorFn(option);
      if (option.subtext) {
        out = out.concat(option.subtext);
      }
      return out;
    }
  });

  /**
   * append select all on top of options list
   */
  const filterOptions = (allOptions, params): Array<OptionsType> => {
    let filteredItems;

    if (asyncSearch) {
      filteredItems = allOptions;
    } else {
      filteredItems = filter(allOptions, params);
    }

    filteredList.current = filteredItems;

    if (renderSelectAll && filteredItems.length > 1) {
      return [selectAll, ...filteredItems];
    }

    if (filterOptionsCallback) {
      return filterOptionsCallback({ filteredItems, inputValue });
    }

    return filteredItems;
  };

  /**
   * method to be in sync with the selection list, on change of select all state
   * if any filter is applied then take up just non selected filtered items
   */
  const handleSelectAll = isSelected => {
    const filterApplied =
      filteredList.current.length > 0 && filteredList.current.length < optionsList.length;

    let updatedList = optionsList.filter(option => !option.disabled);

    if (filterApplied) {
      if (isSelected) {
        const nonSelectedFromFilteredList = filteredList.current.filter(option => {
          const optionId = valueAccessorFn(option);

          return !selectedOption.find(
            selectedElement => valueAccessorFn(selectedElement) === optionId
          );
        });

        updatedList =
          Array.isArray(selectedOption) && selectedOption.length
            ? [...selectedOption, ...nonSelectedFromFilteredList]
            : filteredList.current;
      } else {
        updatedList = selectedOption.filter(option => {
          const optionId = valueAccessorFn(option);

          return !filteredList.current.find(
            filteredElement => valueAccessorFn(filteredElement) === optionId
          );
        });
      }
    }

    setAllSelected(isSelected);

    if (!isSelected && !filterApplied) {
      onClearAllSelectedTags();
      return;
    }

    setSelectedOption(updatedList);
    onChangeEventHandler(updatedList);
  };

  /**
   * maintaining the state for change in the input value
   * if search term is cleared then update the state of select all
   */
  const onInputValueChange = newValue => {
    setInputValue(newValue);

    if (typeof inputValueChangeCallback === 'function') {
      inputValueChangeCallback(newValue);
    }

    if (renderSelectAll && newValue.length === 0) {
      setAllSelected(selectedOption.length === optionsList.length);
    }
  };

  const NoOptionsComponentCallback = useCallback(
    () =>
      NoOptionsComponent ? (
        <NoOptionsComponent
          {...noOptionsComponentProps}
          inputText={inputValue}
          options={optionsList}
        />
      ) : (
        <>{!options.length ? noOptionsText || 'No options found.' : 'No match found.'}</>
      ),
    [inputValue, optionsList, noOptionsText]
  );

  const onChangeEvent = (event, newValue, reason) => {
    if (
      event?.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    if (multiple && renderSelectAll && (reason === 'selectOption' || reason === 'removeOption')) {
      if (
        newValue.find(option => valueAccessorFn(option) === SELECT_ALL_ID) ||
        newValue.length === optionsList.length
      ) {
        handleSelectAll(!allSelected);
        return;
      }

      setAllSelected(false);
    }

    onChangeEventHandler(newValue);
    setSelectedOption(newValue);
  };

  const getSelectedOption = (option): OptionsType => {
    let singleOption: OptionsType;

    if (Array.isArray(option)) {
      [singleOption] = option;
    } else {
      singleOption = option;
    }

    return singleOption;
  };

  const onClearAllSelectedTags = () => {
    const defaultSelectedValue = multiple ? [] : null;

    setAllSelected(false);
    setSelectedOption(defaultSelectedValue);

    onChangeEventHandler(defaultSelectedValue);
  };

  const PopperComponentCallback = useCallback(props => {
    if (PopperComponent) {
      return PopperComponent({
        ...props,
        autoWidthPaperMode,
        HelperDocumentAdornment,
        helperDocumentAdornmentClass,
        helperAdornmentDirection,
        hoverOptionRef,
        setHoverOption
      });
    }
    return DefaultPopperComponent({
      ...props,
      autoWidthPaperMode,
      HelperDocumentAdornment,
      helperDocumentAdornmentClass,
      helperAdornmentDirection,
      hoverOptionRef,
      popperContentClassName,
      setHoverOption
    });
  }, []);

  const PaperComponentCallback = useCallback(
    props =>
      PaperComponent({
        ...props,
        paperClasses,
        TopAdornment,
        BottomAdornment,
        HelperDocumentAdornment,
        helperAdornmentDirection,
        hoverOptionRef,
        loading,
        topAdornmentProps: topAdornmentPropsRef.current,
        bottomAdornmentProps: bottomAdornmentPropsRef.current,
        filteredItems: filteredList.current
      }),
    [loading]
  );

  /*
   * multiple -> dropdown icon (no clear icon since tags have clear icon)
   * single + selected -> clear icon
   * single + empty -> dropdown icon
   * */
  const showPopupIcon =
    (((!multiple && !getSelectedOption(selectedOption)) || multiple) && !isOpened) || selectMode;
  const showClearIcon = getSelectedOption(selectedOption) && !multiple && !selectMode;
  /**
   * disabledDropdown true to disable mui autocomplete directly via props
   * false if it's disabled via css, not through mui props
   */
  const disabledDropdown = disabled;
  const cssDisabledDropdown = !!(disabledDropdown && multiple && selectedOption.length);
  const strictDisabledDropdown = !!(!cssDisabledDropdown && disabledDropdown);
  const skipVirtualization = !!group || disableVirtualization;

  const listBoxProps: any = {
    bottomScrollSentryRef
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'dropdown'), [dataId]);

  return (
    <Autocomplete
      open={renderInline || isOpened}
      ref={dropDownRef}
      id={id}
      disableClearable={hideClearable}
      value={sanitizeSelectedValue(selectedOption, multiple)}
      options={optionsList}
      getOptionLabel={option => displayAccessorFn(getSelectedOption(option))}
      isOptionEqualToValue={(option, value) =>
        valueAccessorFn(getSelectedOption(value)) === SELECT_ALL_ID ||
        valueAccessorFn(getSelectedOption(option)) === valueAccessorFn(getSelectedOption(value))
      }
      openOnFocus
      onFocus={() => {
        if (onFocus) {
          onFocus();
        }
      }}
      disabled={strictDisabledDropdown}
      noOptionsText={<NoOptionsComponentCallback />}
      PaperComponent={PaperComponentCallback}
      ListboxComponent={skipVirtualization ? ListboxComponentGrouped : ListboxComponentVirtualized}
      ListboxProps={listBoxProps}
      PopperComponent={PopperComponentCallback}
      renderInput={props =>
        DefaultInputComponent({
          ...props,
          label,
          displayAccessorFn,
          placeholder,
          helperText,
          required,
          error,
          suffixText,
          prefixElement,
          suffixElement,
          prefixIcon,
          prefixText,
          classStyles,
          isOpened,
          setIsOpened,
          ...inputFieldProps,
          selectMode,
          renderInline,
          SelectedOptionRenderInSelectMode,
          selectedOption,
          cssDisabledDropdown,
          onInputValueChange,
          InputLabelProps,
          InputComponent,
          dataIdGenerator
        })
      }
      renderTags={tagValue =>
        SelectedTagsComponent({
          cssDisabledDropdown,
          displayAccessorFn,
          tagValue,
          selectMode,
          optionsList,
          onClearAllSelectedTags,
          disableTags: multiple && renderInline,
          onClickDisabledTag: () => setIsOpened(true),
          dataIdGenerator
        })
      }
      classes={classes}
      renderOption={(props, option, state) =>
        OptionComponent({
          ...props,
          option,
          disabledOptions,
          displayAccessorFn,
          valueAccessorFn,
          OptionTooltipComponent,
          ...state,
          CustomOption,
          setHoverOption,
          onClose: () => setIsOpened(false),
          disabledDropdown,
          multiple,
          hoverOptionRef,
          getSelectedOption,
          allSelected,
          dataIdGenerator,
          customOptionWrapperClass
        })
      }
      popupIcon={PopupIcon({ showIcon: showPopupIcon })}
      clearIcon={ClearIcon({ showIcon: showClearIcon })}
      filterOptions={filterOptions}
      onChange={(event, newValue, reason) => onChangeEvent(event, newValue, reason)}
      onOpen={e => {
        if (typeof onOpen === 'function') {
          onOpen(e);
        }

        setIsOpened(true);
      }}
      onClose={() => setIsOpened(false)}
      onBlur={onBlur}
      componentsProps={{
        clearIndicator: {
          tabIndex: 0
        }
      }}
      getOptionDisabled={option => getSelectedOption(option)?.disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      className={className}
      size={size}
      onInputChange={(e, v, r) => {
        if (typeof onSearch === 'function' && r !== 'reset') {
          onSearch(v);
        }
      }}
      {...conditionalProps}
    />
  );
}

function DefaultPopperComponent(props) {
  const {
    children,
    style,
    autoWidthPaperMode,
    hoverOptionRef,
    HelperDocumentAdornment,
    helperDocumentAdornmentClass = '',
    helperAdornmentDirection = 'right',
    anchorEl,
    className,
    setHoverOption,
    popperContentClassName,
    ...others
  } = props;

  const showSupportDialog = !!hoverOptionRef.current && !!HelperDocumentAdornment;

  return (
    <Popper
      placement={helperAdornmentDirection === 'right' ? 'bottom-start' : 'bottom-end'}
      className={clsx(className, styles.popperRoot)}
      anchorEl={anchorEl}
      onMouseLeave={() => {
        setHoverOption(null);
      }}
      {...others}
    >
      <div className={clsx('d-flex mb-1', styles.popperShadow, styles.autoCompletePopper, popperContentClassName)}>
        {showSupportDialog && helperAdornmentDirection === 'left' && (
          <div
            className={`${styles.helperWindow} ${styles.helperWindowLeft} ${helperDocumentAdornmentClass}`}
          >
            <HelperDocumentAdornment option={hoverOptionRef.current} />
          </div>
        )}

        <div
          style={{
            width: anchorEl && !autoWidthPaperMode ? anchorEl.clientWidth : null,
            minWidth: anchorEl ? anchorEl.clientWidth : null
          }}
          className='w-100'
        >
          {children}
        </div>

        {showSupportDialog && helperAdornmentDirection === 'right' && (
          <div
            className={`${styles.helperWindow} ${styles.helperWindowRight} ${helperDocumentAdornmentClass}`}
          >
            <HelperDocumentAdornment option={hoverOptionRef.current} />
          </div>
        )}
      </div>
    </Popper>
  );
}

function PaperComponent(props) {
  const paperRef = useRef(null);
  const {
    paperClasses,
    TopAdornment,
    BottomAdornment,
    topAdornmentProps = {},
    bottomAdornmentProps = {},
    loading,
    children,
    HelperDocumentAdornment,
    helperAdornmentDirection = 'right',
    hoverOptionRef,
    filteredItems,
    ...others
  } = props;

  const showSupportDialog = !!hoverOptionRef.current && !!HelperDocumentAdornment;
  const clearFocus = () => {
    if (paperRef.current) {
      const focusedElement = paperRef.current.querySelector('.Mui-focused');

      if (focusedElement) {
        focusedElement.classList.remove('Mui-focused');
      }
    }
  };

  return (
    <HdAutocompletePaper
      {...others}
      className={clsx(styles.dropdownWrapper, paperClasses, {
        [styles.helperDocumentRight]: showSupportDialog && helperAdornmentDirection === 'right',
        [styles.helperDocumentLeft]: showSupportDialog && helperAdornmentDirection === 'left'
      })}
      id='dropdown-paper'
      placement='bottom'
      ref={paperRef}
    >
      {TopAdornment ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={styles.adornmentContainer}
          onMouseDown={e => e.preventDefault()}
          onMouseEnter={clearFocus}
        >
          <TopAdornment {...topAdornmentProps} />{' '}
        </div>
      ) : null}

      {loading && !filteredItems?.length ? null : children}

      {loading ? <div className={autocompleteClasses.loading}>Loading…</div> : null}

      {BottomAdornment ? (
        <div className={styles.adornmentContainer} onMouseEnter={clearFocus}>
          <BottomAdornment {...bottomAdornmentProps} />
        </div>
      ) : null}
    </HdAutocompletePaper>
  );
}

const ListboxComponentGrouped = forwardRef<any, any>(
  ({ bottomScrollSentryRef, children, ...rest }, ref) => (
    <div className='w-100' ref={ref} {...rest}>
      {children}

      <div ref={bottomScrollSentryRef} />
    </div>
  )
);

const ListboxComponentVirtualized = forwardRef<any, any>(
  ({ bottomScrollSentryRef, children, role, ...rest }, ref) => {
    const listboxRef = useRef(null);
    const cellMeasurerCache = useRef(
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: LIST_DEFAULT_ITEM_HEIGHT
      })
    );

    const itemCount = Array.isArray(children) ? children.length : 0;
    const listWidth = listboxRef.current?.clientWidth;
    const listHeight = LIST_DEFAULT_ITEM_HEIGHT * itemCount;

    const rowRenderer = props => {
      const rowElement = React.cloneElement(children[props.index], {
        style: props.style,
        key: props.index,
        ...props
      });

      return (
        <CellMeasurer
          key={props.index}
          cache={cellMeasurerCache.current}
          parent={props.parent}
          columnIndex={0}
          rowIndex={props.index}
        >
          {rowElement}
        </CellMeasurer>
      );
    };

    return (
      <div ref={listboxRef} className='w-100'>
        <div ref={ref} {...rest} style={{ height: listHeight }}>
          <AutoSizer defaultWidth={listWidth} defaultHeight={listHeight}>
            {({ height, width }) => (
              <List
                height={height > 0 ? height : 40}
                width={width > 0 ? width : 40}
                deferredMeasurementCache={cellMeasurerCache.current}
                rowHeight={
                  cellMeasurerCache.current.rowHeight > 0 ? cellMeasurerCache.current.rowHeight : 40
                }
                overscanCount={5}
                rowCount={itemCount}
                role='listbox'
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>

          <div ref={bottomScrollSentryRef} />
        </div>
      </div>
    );
  }
);

function OptionComponent(props: HdDropDownOptionProps) {
  const {
    customOptionWrapperClass,
    option,
    selected,
    multiple,
    onClose,
    disabledOptions,
    disabledDropdown,
    CustomOption,
    inputValue,
    hoverOptionRef,
    setHoverOption,
    OptionTooltipComponent,
    valueAccessorFn,
    displayAccessorFn,
    getSelectedOption,
    allSelected,
    dataIdGenerator,
    onClick,
    ...others
  } = props;
  const selectedOption = getSelectedOption(option);
  const { disabled } = selectedOption;
  const name = displayAccessorFn(selectedOption);
  const id = valueAccessorFn(selectedOption);
  const allSelectedFlag = id === SELECT_ALL_ID && allSelected;
  const selectAllProps = allSelectedFlag ? { checked: allSelected } : {};

  let optionDisabled = disabled || false;
  if (disabledDropdown) {
    optionDisabled = disabledDropdown;
  }

  if (disabledOptions) {
    optionDisabled = disabledOptions.get(id) || optionDisabled;
  }

  const tooltipContent = OptionTooltipComponent
    ? OptionTooltipComponent(selectedOption, optionDisabled)
    : null;

  return (
    <Box
      {...others}
      data-id={dataIdGenerator(others['data-option-index'])}
      key={others['data-option-index']}
      onMouseEnter={() => setHoverOption(option)}
      onClick={e => {
        if (optionDisabled) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        onClick(e);
      }}
      className={clsx(
        styles.itemWrapper,
        selected && !multiple && styles.selected,
        ((multiple && selected) || allSelectedFlag) && styles.multipleSelected,
        optionDisabled && styles.disabled,
        customOptionWrapperClass && customOptionWrapperClass(option)
      )}
    >
      <Box className={`${styles.textWrapper} w-100`}>
        {multiple ? (
          <HdTooltip title={tooltipContent} disableFocusListener disabled={!tooltipContent}>
            <span>
              <HdCheckbox
                dataId={dataIdGenerator(`item-${id}`)}
                checked={selected}
                disabled={optionDisabled}
                className={clsx('mr-1', optionDisabled && 'pointer-none')}
                {...selectAllProps}
              />
            </span>
          </HdTooltip>
        ) : null}

        {CustomOption ? (
          <CustomOption option={selectedOption} isSelectedOption={selected} onClose={onClose} />
        ) : (
          <Typography variant='body2' component='span' className={styles.text}>
            {name}
          </Typography>
        )}
      </Box>

      <Box component={Done} className={styles.icon} />
    </Box>
  );
}

function Done({ className }) {
  return <HdIcon className={className} name='checked-tick' />;
}

function DefaultInputComponent(props) {
  const {
    label,
    placeholder,
    helperText,
    required,
    error,
    suffixText,
    prefixElement,
    suffixElement,
    prefixIcon,
    prefixText,
    isOpened,
    setIsOpened,
    renderInline,
    selectMode,
    SelectedOptionRenderInSelectMode,
    showEndIcon,
    displayAccessorFn,
    selectedOption,
    cssDisabledDropdown,
    onInputValueChange,
    inputProps,
    size,
    InputProps,
    InputLabelProps,
    InputComponent,
    dataIdGenerator,
    ...remaining
  } = props;

  let conditionalProps = {};

  if (cssDisabledDropdown) {
    conditionalProps = { disabled: cssDisabledDropdown };
  }

  let displayTextClassNames = styles.selectModeDisplay;

  if (size === 'small') {
    displayTextClassNames += ` ${styles.selectModeDisplaySmall}`;
  }

  let endAdornment = suffixElement;

  if (suffixElement && showEndIcon) {
    endAdornment = (
      <>
        {suffixElement}
        {InputProps.endAdornment}
      </>
    );
  }

  const isMultiSelect = Array.isArray(selectedOption);

  const getFormattedText = () => {
    if (!selectedOption) {
      if (isOpened) {
        return '';
      }

      if (!isOpened && label) {
        return '';
      }
      return placeholder || label;
    }

    if (!isMultiSelect) {
      return displayAccessorFn(selectedOption);
    }

    // Multi select with select mode

    const maxTextLength = 25;
    const [firstSelectedOption] = selectedOption;
    let tagPlaceholder: string = displayAccessorFn(firstSelectedOption);

    if (displayAccessorFn(firstSelectedOption).length > maxTextLength) {
      tagPlaceholder = `${displayAccessorFn(firstSelectedOption).substring(0, maxTextLength)}...`;
    }

    if (selectedOption.length > 1) {
      tagPlaceholder = `${tagPlaceholder} +${selectedOption.length - 1}`;
    }

    return tagPlaceholder;
  };

  const renderSelectMode = () => {
    if (SelectedOptionRenderInSelectMode) {
      return (
        <div className={styles.selectModeDisplay}>
          <SelectedOptionRenderInSelectMode option={selectedOption} />
        </div>
      );
    }
    const displayText = getFormattedText();

    return (
      <div
        className={clsx(styles.selectModeDisplayText, displayText && 'text-default text-medium', {
          'pl-5': prefixIcon
        })}
      >
        {displayText}
      </div>
    );
  };

  return (
    <div className='position-relative'>
      {selectMode && !renderInline && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className={displayTextClassNames}
          onClick={e => {
            setIsOpened(!isOpened);
          }}
        >
          {renderSelectMode()}
        </div>
      )}

      {InputComponent ? (
        <InputComponent
          {...remaining}
          inputProps={inputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={InputProps}
          placeholder={placeholder}
          selectMode={selectMode}
          onChange={event => onInputValueChange(event.target.value)}
        />
      ) : (
        <HdTextField
          {...remaining}
          required={required}
          label={label}
          placeholder={placeholder || label}
          prefixElement={prefixElement}
          helperText={helperText}
          suffixText={suffixText}
          prefixIcon={prefixIcon}
          suffixElement={endAdornment}
          prefixText={prefixText}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={{ onClick: event => event.stopPropagation() }}
          error={error ? true : undefined}
          className={`${styles.textField} ${selectMode ? 'HdTextField-visuallyHidden' : ''}`}
          size={size}
          onChange={event => onInputValueChange(event.target.value)}
          {...conditionalProps}
          dataId={dataIdGenerator('')}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            readOnly: !!cssDisabledDropdown || selectMode,
            ...inputProps
          }}
        />
      )}
    </div>
  );
}

function SelectedTagsComponent(props) {
  const {
    cssDisabledDropdown,
    tagValue,
    displayAccessorFn,
    disableTags,
    selectMode,
    optionsList,
    onClearAllSelectedTags,
    onClickDisabledTag,
    dataIdGenerator
  } = props;

  if (!Array.isArray(tagValue) || selectMode || disableTags) {
    return null;
  }

  const maxTextLength = 10;
  const allSelectedText = 'All';

  const [firstSelectedOption] = tagValue;

  let tagPlaceholder: string = displayAccessorFn(firstSelectedOption);

  if (tagValue.length > 1 && tagValue.length === optionsList.length) {
    tagPlaceholder = allSelectedText;
  } else {
    if (displayAccessorFn(firstSelectedOption).length > 10) {
      tagPlaceholder = `${displayAccessorFn(firstSelectedOption).substring(0, maxTextLength)}...`;
    }

    if (tagValue.length > 1) {
      tagPlaceholder = `${tagPlaceholder} +${tagValue.length - 1}`;
    }
  }

  let tagProps: { onClose?: Function; onClick?: Function } = { onClose: onClearAllSelectedTags };

  if (cssDisabledDropdown) {
    tagProps = { onClick: onClickDisabledTag };
  }
  return (
    <HdTag dataId={dataIdGenerator('multi-selected')} {...tagProps}>
      {tagPlaceholder}
    </HdTag>
  );
}

/**
 * caret to be in sync with angular integration for time being
 * hide if any item selected or dropdown is in opened state
 */
function PopupIcon({ showIcon }) {
  return showIcon ? <HdIcon name='dropdown-arrow' className={styles.caretIcon} /> : null;
}

function ClearIcon({ showIcon }) {
  return showIcon ? <HdIcon name='close' size={2} className={styles.clearIcon} /> : null;
}

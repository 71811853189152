export enum NPSFeedbackPlaceholder {
  NOT_LIKELY = 'Let it out. We\'re Listening!',
  AVERAGE = 'Tell us first thing that comes to your mind…',
  LIKELY = 'Your words are our badge of honor.'
}

export enum NPSFeedbackQuestion {
  NOT_LIKELY = 'What was missing or disappointing in your experience with Hevo? 😣',
  AVERAGE = 'What is the one thing you would like us to improve upon? 🙁',
  LIKELY = 'What do you love about Hevo? 😍'
}

export interface NPSLowRatingReason {
  name: string;
  value: string;
}
